import React, { useState, useEffect } from 'react';
import axios from './custom-axios/axios';
import "./DropdownPollutant.css"
import 'bootstrap/dist/css/bootstrap.min.css'


function Dropdown (props) {
  const [selectedValue, setSelectedValue] = useState();
  const [options, setOptions] = useState([]);
  const [pollutant, setPollutant] = useState('NOTHAS_ALERT');

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // async function fetchData() {
  //   let arePollutantsFetched = true;
  //   const pollutants = await axios.get("/types")
  //     .catch(() => {
  //       arePollutantsFetched = false;
  //       setOptions(['Options failed to load.'])
  //     });
  //     if(arePollutantsFetched) {
  //       setOptions(pollutants.data);
  //     }
  // }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.changePollutantFun(event.target.value);
  }

  return (
    <div className='selectPollutantSwitch'>
      <label className='selectPollutantLabel' htmlFor="pollutant-select">Избери тип: </label>
      <select className='selectPollutant' id="pollutant-select" value={selectedValue} onChange={handleChange}>
        {/* {options.map(pollutant => (
          <option className='selectPollutantOption' key={pollutant} value={pollutant}>{pollutant}</option>
        ))} */}
          <option className='selectPollutantOption' key={1} value={pollutant}>{pollutant}</option>
      </select>
    </div>
  );
}

export default Dropdown;
