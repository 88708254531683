import React, { useState } from 'react';
import "./DropdownPollutant.css"
import 'bootstrap/dist/css/bootstrap.min.css'


function DropdownDomain (props) {
  const [selectedValue, setSelectedValue] = useState();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.changeDomain(event.target.value);
  }

  return (
    <div className='selectPollutantSwitch selectDomain'>
      <label className='selectPollutantLabel' htmlFor="pollutant-select">Избери регион: </label>
      <select className='selectPollutant' id="pollutant-select" value={selectedValue} onChange={handleChange}>
      <option className='selectPollutantOption' key={2} value={1}>ЈУГОИСТОЧНА ЕВРОПА</option>
          <option className='selectPollutantOption' key={1} value={3}>МАКЕДОНИЈА</option>
      </select>
    </div>
  );
}

export default DropdownDomain;
